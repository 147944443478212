import { Controller } from 'stimulus'

export default class extends Controller {
  update(event) {
    const name = event.target.value.toLowerCase()

    const users = document.querySelectorAll('[data-filter-list-user-name]')
    users.forEach(function(user) {
      const show = !name || name == '' || user.dataset.filterListUserName.toLowerCase().includes(name)
      if (show) {
        user.style.display = 'flex'
      } else {
        user.style.display = 'none'
      }
    });
  }
}
