import { Controller } from 'stimulus';

/**
 * @typedef {Object} SelfServeLaunchToggleController
 * @property {unknown} dateselectorTarget
 * @property {unknown} hasDateselectorTarget
 * @property {unknown} hasInitialTypeOfSendValue
 * @property {unknown} initialTypeOfSendValue
 */
export default class extends Controller {
  static targets = ['dateselector'];

  static values = {
    initialTypeOfSend: String
  };

  connect() {
    /** @type {SelfServeLaunchToggleController} */
    const that = this;

    if (!that.hasDateselectorTarget) {
      throw new Error('expected to dateselector target');
    }

    if (!that.hasInitialTypeOfSendValue) {
      throw new Error('expected to find initial-type-of-send value');
    }

    if (that.initialTypeOfSendValue == 'send_later') { // Team::LaunchTypes::SEND_LATER
      this.dateselectorTarget.classList.remove('is-hidden');
    }
  }

  send_now() { // underscore for grepping
    this.dateselectorTarget.classList.add('is-hidden');
  }

  send_later() {
    this.dateselectorTarget.classList.remove('is-hidden');
  }
}
