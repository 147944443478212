import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { labels: Array, data: Array }

  connect() {
    console.log('connect people stats')
    console.log(this.labelsValue)
    console.log(this.dataValue)

    // var ctx = document.getElementById('peopleStats');
    var ctx = this.element
    var peopleStats = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: this.labelsValue,
        datasets: [
          {
            label: "",
            backgroundColor: ["#f14b00", "#f1a500", "#66ce00", "#44c1ce", "#491cff"],
            data: this.dataValue
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        legend: { display: false },
        title: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 25,
              stepSize: 5,
              fontColor: '#8dabc4'
            }
          }],
          yAxes: [{
            barThickness: 4
          }]
        }
      }
    });
  }
}
