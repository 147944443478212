// IE11 https://github.com/rails/webpacker/issues/2115#issuecomment-500125009
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import "dom4";
import '@webcomponents/webcomponentsjs';
import 'intersection-observer';
import 'abortcontroller-polyfill';

import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

import 'controllers';

Rails.start();

require("trix");
require("@rails/actiontext");
