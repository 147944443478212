import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input']

  add(e) {
    if (this.inputTarget.value == '') {
      this.inputTarget.value = e.target.innerText
    } else {
      this.inputTarget.value += ', ' + e.target.innerText
    }
  }
}
