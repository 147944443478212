import { Controller } from "stimulus"

const managersCountKey = 'roi.managersCount'
const teamSizeKey = 'roi.teamSize'
const averageSalaryKey = 'roi.averageSalary'

export default class extends Controller {
  static targets = ['managersCount', 'teamSize', 'averageSalary', 'salaryPower', 'powerX3']

  static values = {
    managersCount: Number,
    teamSize: Number,
    averageSalary: Number
  }

  initialize() {
    const managersCount = localStorage.getItem(managersCountKey)
    if (managersCount) {
      this.managersCountValue = managersCount
    } else {
      this.managersCountValue = 5
    }

    const teamSize = localStorage.getItem(teamSizeKey)
    if (teamSize) {
      this.teamSizeValue = teamSize
    } else {
      this.teamSizeValue = 7
    }

    const averageSalary = localStorage.getItem(averageSalaryKey)
    if (averageSalary) {
      this.averageSalaryValue = averageSalary
    } else {
      this.averageSalaryValue = 56000
    }
  }

  managersCountChanged(event) {
    this.managersCountValue = event.target.value
    localStorage.setItem(managersCountKey, this.managersCountValue)
  }
  teamSizeChanged(event) {
    this.teamSizeValue = event.target.value
    localStorage.setItem(teamSizeKey, this.teamSizeValue)
  }
  averageSalaryChanged(event) {
    this.averageSalaryValue = event.target.value
    localStorage.setItem(averageSalaryKey, this.averageSalaryValue)
  }


  ///////////////
  // callbacks //
  ///////////////
  managersCountValueChanged() {
    this.managersCountTarget.value = this.managersCountValue
    this.recount()
  }

  teamSizeValueChanged() {
    this.teamSizeTarget.value = this.teamSizeValue
    this.recount()
  }
  averageSalaryValueChanged() {
    this.averageSalaryTarget.value = this.averageSalaryValue
    this.recount()
  }

  recount() {
    if (this.managersCountValue && this.teamSizeValue && this.averageSalaryValue) {
      const salaryPower = this.managersCountValue * this.teamSizeValue * this.averageSalaryValue

      this.salaryPowerTarget.innerHTML = salaryPower.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }).replace('.00', '');

      this.powerX3Target.innerHTML = (salaryPower * 3).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }).replace('.00', '');
    }
  }
}
