import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mandatoryRow', 'leftSideRow', 'rightSideRow']

  typeChanged(e) {
    const value = e.target.value;
    if (value == 'slider' || value == 'slider-2' ) { // greppable: SLIDER = 'slider' SLIDER2 = 'slider-2'
      this.mandatoryRowTarget.classList.remove('is-hidden')

      this.leftSideRowTarget.classList.remove('is-hidden')
      this.rightSideRowTarget.classList.remove('is-hidden')
    } else {
      this.mandatoryRowTarget.classList.add('is-hidden')

      this.leftSideRowTarget.classList.add('is-hidden')
      this.rightSideRowTarget.classList.add('is-hidden')
    }
  }
}
