import { Controller } from 'stimulus';

/**
 * @typedef {Object} CoachController
 * @property {unknown} soloValue
 * @property {unknown} teamIdValue
 * @property {boolean} hasSoloValue
 * @property {boolean} hasTeamIdValue false for new cohorts/solo enrollments
 * @property {Element} emailTarget
 * @property {boolean} hasEmailTarget
 * @property {Element} nameTarget
 * @property {boolean} hasNameTarget
 * @property {Element} timeZoneTarget
 * @property {boolean} hasTimeZoneTarget
 * @property {Element} selectedTarget
 * @property {boolean} hasSelectedTarget
 */
export default class extends Controller {
  static targets = ['email', 'name', 'timeZone', 'selected'];

  static values = { solo: Boolean, teamId: Number };

  connect() {
    /** @type {CoachController} */
    const that = this;

    if (!that.hasSoloValue) throw new Error('expected to find solo value attribute');
  }

  /**
   * @param { Event } event
   */
  nameChanged(event) {
    /** @type {CoachController} */
    const that = this;

    if (event instanceof InputEvent) {
      // Ignore, still typing manually
    } else if (event instanceof Event) {
      // Selected from the datalist

      const nameSelected = event.target.value;
      const email = document.querySelector('datalist option[value="' + nameSelected + '"]').dataset.email;

      that.emailTarget.value = email;
    }
  }

  /**
   * @param { Event } event
   */
  addCoach(event) {
    /** @type {HTMLButtonElement} */
    const buttonTarget = event.target;
    if (buttonTarget.disabled) return;
    buttonTarget.disabled = true;

    /** @type {CoachController} */
    const that = this;

    const organization_id = parseFloat(document.querySelector('#team_organization_id').value, 10);

    const data = {
      organization_id,
      solo: that.soloValue,
      name: that.nameTarget.value,
      email: that.emailTarget.value,
      time_zone: that.timeZoneTarget.value
    };
    if (that.hasTeamIdValue) data['team_id'] = that.teamIdValue;


    let token = document.querySelector('meta[name="csrf-token"]').content;
    fetch('/coachings', { // coachings_path
      method: 'POST',
      headers: {
        'Accept': 'application/javascript',
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(js => eval(js));
  }
}
