import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    data: Object,
    color: String
  }

  connect() {
    var ctx = this.element;
    var rawData = this.dataValue
    var color = this.colorValue;
    var labels = Object.keys(rawData).map(function(key) {
      return new Date(key * 1000);
    })
    var values = Object.values(rawData).map(function(value) {
      return value * 1;
    })
    var engagementChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          data: values,
          label: "Engagement",
          borderWidth: 4,
          fill: false,
          backgroundColor: color,
          borderColor: color,
        }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#8dabc4',
              callback: function(value, index, values) {
                return new Date(value).toLocaleDateString('en-gb', {
                  year: 'numeric',
                  month: '2-digit',
                  day: 'numeric'
                });
              }
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#8dabc4'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    });


  }
}
