import { Controller } from 'stimulus';

export default class extends Controller {
  delete() {
    this.element.remove();

    const container = document.querySelector('[data-controller="coach"]');
    const count = container.querySelectorAll('.selected-people__body-row').length;
    if (count == 0) {
      container.querySelector('.selected-people').classList.add('is-hidden')
      container.querySelector('.add-person__form').classList.remove('is-hidden')
    } else {
      container.querySelector('.selected-people').classList.remove('is-hidden')
    }
  }
}
