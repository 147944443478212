import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['openButton', 'closeButton', 'content'];

  openMenu(event) {
    // this.openButtonTarget.classList.add('is-hidden');
    // this.closeButtonTarget.classList.remove('is-hidden');

    this.contentTarget.classList.remove('is-hidden');
  }

  closeMenu(event) {
    // this.closeButtonTarget.classList.add('is-hidden');
    // this.openButtonTarget.classList.remove('is-hidden');

    this.contentTarget.classList.add('is-hidden');
  }
}
