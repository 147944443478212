import { Controller } from 'stimulus';

// see participant_controller
/**
 * @typedef {Object} ModalPopupController
 */
export default class extends Controller {
  static values = {
    autoshow: Boolean
  }
  connect() {
    if (this.hasAutoshowValue && this.autoshowValue) {
      this.show();
    }
  }

  show() {
    this.element.style.visibility = 'visible';
    this.element.style.opacity = '1';
  }

  close() {
    this.element.remove();
  }
}
