// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import Timeago from 'stimulus-timeago';
import Popover from 'stimulus-popover';
import Chart from 'stimulus-chartjs';
import AnimatedNumber from 'stimulus-animated-number';
import Sortable from 'stimulus-sortable';
import Reveal from 'stimulus-reveal-controller';

import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
application.register('timeago', Timeago);
application.register('chart', Chart);
application.register('popover', Popover);
application.register('animated-number', AnimatedNumber);
application.register('sortable', Sortable);
application.register('reveal', Reveal);

const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
