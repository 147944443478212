import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    var fadeTarget = this.element;

    setTimeout(() => {
      fadeTarget.style.transition = '0.8s';
      fadeTarget.style.opacity = 0;
      fadeTarget.remove();
    }, 7000);
  }
}
