import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.conditionalExpand();
  }

  conditionalExpand() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (width <= 1200) {
      this.element.classList.add('collapsed');
    } else {
      this.element.classList.remove('collapsed');
    }
  }
}
