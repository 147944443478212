import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { percent: Number };

  connect() {
    var $graph = this.element;
    var percent = parseInt(this.percentValue, 10);
    var deg = 360 * percent / 100;

    if (percent > 50) {
      $graph.classList.add('gt-50');
    }
    document.querySelector('.circle-graph-progress-fill').style.transform = 'rotate(' + deg + 'deg)'; // TODO use target instead
    document.querySelector('.circle-graph-percents-number').innerHTML = percent + '%';
  }
}
