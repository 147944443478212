import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['textarea']

  change(e) {
    const element = document.querySelector(`[data-coaching-style="${e.target.value}"]`)
    if (element.length == 0) {
      throw new Error("can not find coaching element for " + e.target.value)
    }

    this.textareaTarget.value = element.innerHTML
  }
}
