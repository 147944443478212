import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['read', 'body']

  show() {
    this.bodyTarget.classList.remove('is-hidden')
    this.readTarget.classList.add('is-hidden')
  }

  hide() {
    this.bodyTarget.classList.add('is-hidden')
    this.readTarget.classList.remove('is-hidden')
  }
}
